
.orgchart ul li .oc-node::after {
    background-color: #628439 !important;
}

.orgchart > ul > li > ul li::before {
    border-color: #628439 !important;
}

.orgchart > ul > li > ul li > .oc-node::before {
    background-color: #628439 !important;
}

.orgchart {
    background-image: linear-gradient(90deg, rgba(0, 77, 64, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(0, 77, 64, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
}

.orgchart-container {
    height: auto !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 0.25rem;
    border-color: rgb(210, 214, 218);
}

.orgchart ul li .oc-node.selected {
    background-color: rgba(98,132,57, 0.5);
    border-radius: 0.75rem;
}

.orgchart ul li .oc-node:hover {
    background-color: rgba(98,132,57, 0.5);
    border-radius: 0.75rem;
}
